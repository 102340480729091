'use client';

import React, { Fragment, useState, FC, useMemo, ReactNode } from 'react';

import { Transition } from '@headlessui/react';

import { set, get } from 'lodash';

import { calculateNestedKeys } from 'helpers/calculate';
import { toClassName } from 'helpers/format';
import { deepCopyObject } from 'helpers/object';

import { TNotificationOverlayStyles, styles as defaultStyles } from '.';

export type TProps = {
  trigger?: ({
    onOpen,
    onClose,
  }: {
    onOpen: () => void;
    onClose: () => void;
  }) => ReactNode;
  children: ({
    onOpen,
    onClose,
  }: {
    onOpen: () => void;
    onClose: () => void;
  }) => ReactNode;

  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  styles?: TNotificationOverlayStyles;
};

const NotificationOverlayContainer = ({
  children,
  styles: stylesOverrides,
}: {
  children: ReactNode;
  styles?: TNotificationOverlayStyles;
}) => {
  const handleGenerateStyle = () => {
    const result = deepCopyObject(defaultStyles.base);
    const keys = calculateNestedKeys(defaultStyles.base);

    keys.forEach((key) => {
      set(
        result,
        key,
        toClassName(get(defaultStyles.base, key), get(stylesOverrides, key)),
      );
    });

    return result;
  };

  const styles = handleGenerateStyle();

  return (
    <div aria-live="assertive" className={styles.container.outer}>
      <div className={styles.container.inner}>{children}</div>
    </div>
  );
};

const NotificationOverlayItem: FC<TProps> = (props) => {
  const { children, trigger, size = 'md', styles: stylesOverrides } = props;

  const [isOpen, setIsOpen] = useState(trigger ? false : true);

  const options = useMemo(
    () => ({
      onOpen: () => setIsOpen(true),
      onClose: () => setIsOpen(false),
    }),
    [],
  );

  const handleGenerateStyle = () => {
    const result = deepCopyObject(defaultStyles.base);
    const keys = calculateNestedKeys(defaultStyles.base);

    keys.forEach((key) => {
      set(
        result,
        key,
        toClassName(
          get(defaultStyles.base, key),
          size && get(defaultStyles[size], key),
          get(stylesOverrides, key),
        ),
      );
    });

    return result;
  };

  const styles = handleGenerateStyle();

  return (
    <>
      <Transition
        show={isOpen}
        as={Fragment}
        enter={styles.item.panel.transition.enter}
        enterFrom={styles.item.panel.transition.enterFrom}
        enterTo={styles.item.panel.transition.enterTo}
        leave={styles.item.panel.transition.leave}
        leaveFrom={styles.item.panel.transition.leaveFrom}
        leaveTo={styles.item.panel.transition.leaveTo}
      >
        <div className={styles.item.panel.container}>{children(options)}</div>
      </Transition>
      {trigger && trigger(options)}
    </>
  );
};

const NotificationOverlay = {
  Container: NotificationOverlayContainer,
  Item: NotificationOverlayItem,
};

export default NotificationOverlay;
