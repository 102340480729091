import React, { FC, HTMLInputTypeAttribute, ReactNode } from "react";

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useMask } from "@react-input/mask";

import { get, set } from "lodash";
// import { useTranslation } from "react-i18next";

import { calculateNestedKeys } from "helpers/calculate";
import { toClassName } from "helpers/format";
import { deepCopyObject } from "helpers/object";

import { TInputGroupStyles, styles as defaultStyles } from ".";

export type TInputGroupFormProps = {
  name: string;
  htmlType: HTMLInputTypeAttribute | "area";

  label?: string | ReactNode;
  description?: string;
  placeholder?: string;

  leading?: string | ReactNode;
  trailing?: string | ReactNode;
  required?: ({ isRequired }: { isRequired: boolean }) => string | ReactNode;

  value: string;
  error?: string;
  mask?: { mask: string; replacementCharacter?: string };
  min?: number;
  max?: number;
  maxLength?: number;
  rows?: number;

  type: "normal" | "inset" | "overlapping" | "pill" | "floored";

  isValid?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isTouched?: boolean;
  isSolo?: boolean;

  onChange: any;
  onBlur: any;

  styles?: TInputGroupStyles;
};

export const InputGroupFormNative: FC<TInputGroupFormProps> = (props) => {
  const {
    name,
    htmlType = "text",

    label,
    description,
    placeholder,

    leading,
    trailing,
    required,

    value,
    mask,
    min,
    max,
    maxLength,
    rows,
    error,

    type = "normal",

    isDisabled,
    isRequired = false,
    isTouched = false,
    isSolo = false,

    onChange,
    onBlur,

    styles: stylesOverrides,
  } = props;

  const isValid = error === undefined;

  const ref = useMask({
    mask: mask?.mask,
    replacement: { [mask?.replacementCharacter ?? "X"]: /\d|\D/ },
  });

  const handleGenerateStyle = () => {
    const result = deepCopyObject(defaultStyles.base);
    const keys = calculateNestedKeys(defaultStyles.base);

    keys.forEach((key) => {
      set(
        result,
        key,
        toClassName(
          get(defaultStyles.base, key),

          leading !== undefined && get(defaultStyles.leading, key),
          trailing !== undefined && get(defaultStyles.trailing, key),

          type && get(defaultStyles[type], key),

          isTouched === true && !isValid && get(defaultStyles.invalid, key),
          isDisabled && get(defaultStyles.disabled, key),

          get(stylesOverrides, key),
        ),
      );
    });

    return result;
  };

  const styles = handleGenerateStyle();

  const invalidIcon = (
    <ExclamationCircleIcon
      className="h-5 w-5 text-red-500"
      aria-hidden="true"
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        {label && (
          <label htmlFor={name} className={styles.label}>
            {label}
          </label>
        )}
        {!required && !isRequired && typeof label === "string" && (
          <span className={styles.hint}>Optional</span>
        )}
        {required && required({ isRequired })}
      </div>
      <div className={styles.body}>
        {leading && <div className={styles.leading}>{leading}</div>}
        {!htmlType ||
          (htmlType !== "area" && (
            <input
              type={mask !== undefined ? "text" : htmlType}
              ref={mask && ref}
              name={name}
              id={name}
              className={toClassName(
                styles.input,
                htmlType === "color" && "!h-10",
              )}
              placeholder={placeholder}
              defaultValue={isSolo ? value : undefined}
              value={isSolo ? undefined : value}
              min={min}
              max={max}
              maxLength={maxLength}
              disabled={isDisabled}
              onChange={onChange}
              onBlur={onBlur}
              autoComplete="off"
            />
          ))}
        {htmlType === "area" && (
          <textarea
            rows={rows}
            name={name}
            id={name}
            className={styles.input}
            placeholder={placeholder}
            defaultValue={isSolo ? value : undefined}
            value={isSolo ? undefined : value}
            maxLength={maxLength}
            disabled={isDisabled}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
        {type === "floored" && (
          <div
            className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-primary-600"
            aria-hidden="true"
          />
        )}
        {trailing && isValid && (
          <div className={styles.trailing}>{trailing}</div>
        )}
        {isTouched && !isValid && (
          <div className={styles.trailing}>{invalidIcon}</div>
        )}
      </div>
      {!isSolo && (
        <div className={styles.foot}>
          {description && !(error && isTouched) && (
            <p className={styles.description}>{description}</p>
          )}
          {error && isTouched && <p className={styles.error}>{error}</p>}
        </div>
      )}
    </div>
  );
};

type TProps = {
  form: any;
  //
  name: string;
  htmlType?: string;
  isRequired?: boolean;
  mask?: any;
  label?: string;
  placeholder?: string;
  description?: string;
  //
  isDisabled?: boolean;
};

const InputGroupForm: FC<TProps> = (props) => {
  const {
    form,
    name,
    mask,
    htmlType = "text",
    label,
    placeholder,
    description,
    isRequired,
    isDisabled,
  } = props;

  // const { t } = useTranslation();

  return (
    <InputGroupFormNative
      name={name}
      type="normal"
      htmlType={htmlType}
      rows={8}
      //
      // label={t(`component.form.label.${name}`)}
      // placeholder={t(`component.form.placeholder.${name}`) as string}
      // description={t(`component.form.description.${name}`) as string}
      label={label}
      placeholder={placeholder}
      description={description}
      //
      value={form.values?.[name]}
      error={form.errors?.[name] as string}
      mask={mask}
      //
      isTouched={form.touched?.[name] as boolean}
      //
      isRequired={isRequired}
      isDisabled={isDisabled}
      //
      required={({ isRequired }) =>
        isRequired && <div className="text-white">*</div>
      }
      //
      onChange={(value: string) => {
        form.handleChange(value);

        // Validate onChange.
        if (form.values?.[name]) {
          form.handleBlur(value);
        }
      }}
      onBlur={form.handleBlur}
      //
      styles={{
        container: "font-body w-full ",
        input: "!rounded-sm",
        // required: '!text-error-500',
        head: "!justify-start",
        label: "mr-1",
        error: "!ml-0 !mt-2",
      }}
    />
  );
};

export default InputGroupForm;
