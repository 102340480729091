import React, { FC, useMemo } from "react";

import { useTranslation } from "react-i18next";

import data from "./data.json";

import { styles } from ".";
import { InputGroupFormNative } from "../InputGroup/InputGroupForm";
import { SelectMenuFormNative } from "../SelectMenu/SelectMenuForm";



type TProps = {
  form: any;
  //
  name: string;
  label?: string;
  description?: string;
  //
  isRequired?: boolean;
  //
  isDisabled?: boolean;
};

const TelephoneInputGroup: FC<TProps> = (props) => {
  const { form, name, label, description, isRequired, isDisabled } = props;

  const { t } = useTranslation();

  const options = useMemo(
    () =>
      data?.map((item) => ({
        label: `${item.icon} ${item.name} (${item.dial_code})`,
        value: item.dial_code,
      })),
    [],
  );

  // const label = t("component.form.label.telephoneNumber");
  // const description = t("component.form.description.telephoneNumber");

  const error =
    form.errors?.[`${name}-countryCode`] || form.errors?.[`${name}-number`];
  const isTouched =
    form.touched?.[`${name}-countryCode`] && form.touched?.[`${name}-number`];

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        {label && (
          <label htmlFor={`${name}-countryCode`} className={styles.label}>
            {label}
          </label>
        )}
        <div className="text-white pl-2">*</div>
      </div>
      <div className="flex gap-x-3">
        <div className="w-[12rem]">
          <SelectMenuFormNative
            isSolo
            name={`${name}-countryCode`}
            // type="normal"
            //
            options={options}
            //
            // label={t(`component.form.label.${name}`)}
            // placeholder={{
            //   select: t(`component.form.placeholder.${name}`) as string,
            // }}
            // description={t(`component.form.description.${name}`) as string}
            //
            value={form.values?.[`${name}-countryCode`]}
            error={form.errors?.[`${name}-countryCode`]}
            //
            isTouched={form.touched?.[`${name}-countryCode`]}
            //
            isRequired={isRequired}
            isDisabled={isDisabled}
            isMultiple={false}
            isSearchable={true}
            isLoading={false}
            //
            // required={({ isRequired }) =>
            //   isRequired && <div className="text-white">*</div>
            // }
            //
            onChange={(value) => {
              form.setFieldValue(`${name}-countryCode`, value);
              form?.setFieldError(`${name}-countryCode`, undefined);
            }}
            onBlur={() => {
              form.setFieldTouched(`${name}-countryCode`);
              form?.validateField(`${name}-countryCode`);
            }}
            onClear={() => {
              form.setFieldValue(`${name}-countryCode`, null);
              form?.validateField(`${name}-countryCode`);
            }}
            //
            // onSearchInputChange={(e) => {
            //   if (endpoint) {
            //     setQuery([e.currentTarget.value as string]);
            //   }
            // }}
            //
            styles={{
              container: "font-body w-full ",
              input: "!rounded-sm",
              // required: '!text-error-500',
              head: "!justify-start",
              label: "mr-1",
              error: "!ml-0 !mt-2",
              listItem: "text-sm",
            }}
          />
        </div>
        <div className="flex-grow z-10">
          <InputGroupFormNative
            isSolo
            name={`${name}-number`}
            type="normal"
            htmlType="text"
            rows={8}
            //
            // label={t(`component.form.label.${name}`)}
            placeholder="486 12 34 56"
            // placeholder={t(`component.form.placeholder.${name}`) as string}
            // description={t(`component.form.description.${name}`) as string}
            //
            value={form.values?.[`${name}-number`]}
            error={form.errors?.[`${name}-number`] as string}
            // mask={{ mask: 'XXX XX XX XX' }}
            //
            isTouched={form.touched?.[`${name}-number`] as boolean}
            //
            isRequired={isRequired}
            isDisabled={isDisabled}
            //
            // required={({ isRequired }) =>
            //   isRequired && <div className="text-white">*</div>
            // }
            //
            onChange={(value: string) => {
              form.handleChange(value);

              // Validate onChange.
              if (form.values?.[`${name}-number`]) {
                form.handleBlur(value);
              }
            }}
            onBlur={form.handleBlur}
            //
            styles={{
              container: "font-body w-full ",
              input: "!rounded-sm",
              // required: '!text-error-500',
              head: "!justify-start",
              label: "mr-1",
              error: "!ml-0 !mt-2",
            }}
          />
        </div>
      </div>
      <div className={styles.foot}>
        {description && !(error && isTouched) && (
          <p className={styles.description}>{description}</p>
        )}
        {error && isTouched && (
          <p className={styles.error}>
            {t("component.form.error.telephoneNumber")}
          </p>
        )}
      </div>
    </div>
  );
};

export default TelephoneInputGroup;
