import React, {
  ChangeEvent,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Option,
  SelectValue,
} from "react-tailwindcss-select/dist/components/type";

import { get, set } from "lodash";
// import { useTranslation } from "react-i18next";
import Select from "react-tailwindcss-select";


import { calculateNestedKeys } from "helpers/calculate";
import { toClassName } from "helpers/format";
import { deepCopyObject } from "helpers/object";

// import { useRead } from 'hooks/useRead';

import { TSelectMenuFormStyles, styles as defaultStyles } from ".";

export type TSelectMenuFormProps = {
  name: string;

  label?: string;
  description?: string;
  placeholder?: {
    select?: string;
    searchInput?: string;
    emptyState?: string;
  };
  options: { value: string; label: string }[];

  required?: ({ isRequired }: { isRequired: boolean }) => string | ReactNode;

  value: { label: string; value: string; disabled?: boolean };
  error?: string | Option | Option[];

  isLoading?: boolean;
  isRequired?: boolean;
  isTouched?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMultiple?: boolean;
  isDarkMode?: boolean;
  isSolo?: boolean;

  onChange?: (value: SelectValue) => void;
  onSearchInputChange?:
    | ((e: ChangeEvent<HTMLInputElement>) => void)
    | undefined;
  onClear: (name: string) => void;

  onBlur?: any;

  styles?: TSelectMenuFormStyles;
};

export const SelectMenuFormNative: FC<TSelectMenuFormProps> = (props) => {
  const {
    name = "select-menu-form",

    label,
    description,
    placeholder,
    options,
    required,

    value,
    error,

    isRequired = false,
    isLoading,
    isTouched = false,
    isDisabled,
    isClearable = true,
    isMultiple = false,
    isSearchable = true,
    isSolo = false,

    onChange,
    onSearchInputChange,
    onClear,
    onBlur,

    styles: stylesOverrides,
  } = props;

  const [isFocus, setIsFocus] = useState(true);
  const [state, setState] = useState<SelectValue>(value ?? null);

  const isValid = error === undefined;

  const handleGenerateStyle = () => {
    const result = deepCopyObject(defaultStyles.base);
    const keys = calculateNestedKeys(defaultStyles.base);

    keys.forEach((key) => {
      set(
        result,
        key,
        toClassName(
          get(defaultStyles.base, key),

          get(defaultStyles["normal"], key),

          isTouched === true && !isValid && get(defaultStyles.invalid, key),
          isDisabled && get(defaultStyles.disabled, key),

          get(stylesOverrides, key),
        ),
      );
    });

    return result;
  };

  const handleChange = (selectValue: SelectValue) => {
    setState(selectValue);
    onChange && onChange(selectValue);
    setIsFocus(false);
  };

  const styles = handleGenerateStyle();

  // Simulate onClear event.
  useEffect(() => {
    if (state === null) {
      onClear && onClear(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Simulate onBlur event.
  useEffect(() => {
    if (!isFocus) {
      onBlur && onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocus]);

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        {label && (
          <label htmlFor={name} className={styles.label}>
            {label}
          </label>
        )}
        {!required && !isRequired && typeof label === "string" && (
          <span className={styles.hint}>Optional</span>
        )}
        {required && required({ isRequired })}
      </div>
      <div className={styles.container}>
        <Select
          primaryColor="primary"
          //
          placeholder={placeholder?.select}
          noOptionsMessage={
            (
              <p className={styles.emptyState}>
                {placeholder?.emptyState ?? "No results found"}
              </p>
            ) as any
          }
          searchInputPlaceholder={placeholder?.searchInput}
          //
          loading={isLoading}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isMultiple={isMultiple}
          isSearchable={isSearchable}
          //
          options={options}
          //
          value={state}
          onChange={handleChange}
          onSearchInputChange={onSearchInputChange}
          //
          classNames={{
            menuButton: () =>
              toClassName(
                styles.input,
                state === null && "[&>*]:!text-[rgba(255,255,255,0.2)] ",
                state === null &&
                  !isValid &&
                  isTouched &&
                  "[&>*]:!text-[rgba(255,0,0,0.2)]",
              ),
            menu: styles.menu,
            tagItem: () => styles.tagItem,
            tagItemText: styles.tagItemText,
            tagItemIconContainer: styles.tagItemIconContainer,
            tagItemIcon: styles.tagItemIcon,
            list: styles.list,
            listItem: () => styles.listItem,
            listDisabledItem: styles.listDisabledItem,
            listGroupLabel: styles.listGroupLabel,
            searchContainer: styles.searchContainer,
            searchBox: styles.searchBox,
            searchIcon: styles.searchIcon,
            closeIcon: styles.closeIcon,
          }}
        />
        {!isSolo && (
          <div className={styles.foot}>
            {description && !(error && isTouched) && (
              <p className={styles.description}>{description}</p>
            )}
            {error && isTouched && (
              <p className={styles.error}>
                {JSON.stringify(error).replaceAll('"', "")}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

type TProps = {
  form: any;
  //
  name: string;
  label?: string;
  placeholder?: string;
  description?: string;
  //
  defaultOptions?: { label: string; value: string }[];
  endpoint?: string;
  //
  isRequired?: boolean;
  isMultiple?: boolean;
  isSearchable?: boolean;
  //
  isDisabled?: boolean;
};

const SelectMenuForm: FC<TProps> = (props) => {
  const {
    form,
    name,
    label,
    placeholder,
    description,
    //
    defaultOptions,
    endpoint,
    //
    isRequired,
    isMultiple,
    isSearchable = false,
    //
    isDisabled,
  } = props;

  // const { t } = useTranslation();

  const [_, setQuery] = useState<string[]>([]);

  // const { data, isLoading } = useRead<string[]>(
  //   () => ({
  //     keys: [`selectMenuForm-${name}`, ...query],
  //     url: endpoint ? `${endpoint}?filter=${query}` : '/fake/options',
  //   }),
  //   [],
  // );

  const options = useMemo(
    () =>
      // endpoint
      //   ? data?.map((value) => ({ label: value, value })) ?? []
      //   :
      defaultOptions ?? [],
    [defaultOptions],
  );

  return (
    <SelectMenuFormNative
      name={name}
      // type="normal"
      //
      options={options}
      //
      // label={t(`component.form.label.${name}`)}
      label={label}
      // placeholder={{
      //   select: t(`component.form.placeholder.${name}`) as string,
      // }}
      placeholder={{ select: placeholder }}
      // description={t(`component.form.description.${name}`) as string}
      description={description}
      //
      value={form.values?.[name]}
      error={form.errors?.[name]}
      //
      isTouched={form.touched?.[name]}
      //
      isRequired={isRequired}
      isDisabled={isDisabled}
      isMultiple={isMultiple}
      isSearchable={isSearchable}
      isLoading={false}
      //
      required={({ isRequired }) =>
        isRequired && <div className="text-white">*</div>
      }
      //
      onChange={(value) => {
        form.setFieldValue(name, value);
        form?.setFieldError(name, undefined);
      }}
      onBlur={() => {
        form.setFieldTouched(name);
        form?.validateField(name);
      }}
      onClear={() => {
        form.setFieldValue(name, null);
        form?.validateField(name);
      }}
      //
      onSearchInputChange={(e) => {
        if (endpoint) {
          setQuery([e.currentTarget.value as string]);
        }
      }}
      //
      styles={{
        container: "font-body w-full ",
        input: "!rounded-sm",
        // required: '!text-error-500',
        head: "!justify-start",
        label: "mr-1",
        error: "!ml-0 !mt-2",
        listItem: "text-sm",
      }}
    />
  );
};

export default SelectMenuForm;
